import React from "react";

const FraternityCuriosities = ({ fraternity }) => {
  if (fraternity.curiosity_set == null) {
    return <div></div>;
  }
  return (
    <section className="p-interesting-facts" id="curiosities">
      <div className="container">
        <h1>Ciekawostki</h1>
        <div>
          <ul>
            {fraternity.curiosity_set.curiosities.map((c, index) => (
              <li><p key={index}>{c.contents}</p></li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FraternityCuriosities;
