import React from 'react'

const FraternityMeetings = ({fraternity}) => {
  if (fraternity.meetings !== null && fraternity.meetings.meetings.length > 0) {
    return (
      <section className="p-worship" id="meetings">
        <div className="container">
          <h1>Spotkania</h1>
          <div className='pull-left-text'>
            {fraternity.meetings.meetings.map((item, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: item.text }}></p>
            ))}
          </div>
          <hr />
          {fraternity.fraternity_patron_commemorating_way !== null && fraternity.fraternity_patron_commemorating_way.length > 10 ? (
        <div
          className="pull-left-text"
          dangerouslySetInnerHTML={{ __html: fraternity.fraternity_patron_commemorating_way }}
        ></div>
      ) : (
        <div></div>
      )}
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
}

export default FraternityMeetings