import React from "react";
import { Link } from "react-router-dom";

const HomeSaints = ({persons}) => {

  var direction = true;
  const personsToShow = [];
  for(var p = 0; p < persons.length; p++) {
    if(persons[p].show_on_home_page)
      personsToShow.push(persons[p]);
  }

  return (
    <section className="saints" id="saints">
      <h1>Święci i zasłużeni</h1>
      <div className="slides">
        <div className="row g-0">
          {personsToShow.map((p, index) => {
            if (index % 2 === 0) {
              direction = !direction;
            }
            if (direction) {
              return (
                <div className="col-lg-6" key={index}>
                  <div className="row g-0">
                    <div className="col-xl slide">
                      <figure>
                        <a
                          data-fancybox="sk"
                          href={p.photo}
                          data-caption={
                            "<b>" +
                            p.name +
                            "</b><br>" +
                            p.subtitle +
                            "<br><br><a href='s/" +
                            p.slug +
                            "' class='btn btn-primary'>Przeczytaj więcej</a>"
                          }
                        >
                          <img
                            className="img-fluid"
                            src={p.thumb}
                            alt={p.name}
                          />
                        </a>
                      </figure>
                    </div>
                    <div className="col-xl">
                      <div className="desc">
                        <Link to={"s/" + p.slug}>
                          <h2>{p.name}</h2>
                          <p>{p.subtitle}</p>
                          <i className="fas fa-chevron-circle-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="col-lg-6" key={index}>
                  <div className="row g-0">
                    <div className="col-xl">
                      <div className="desc">
                        <Link to={"s/" + p.slug}>
                          <h2>{p.name}</h2>
                          <p>{p.subtitle}</p>
                          <i className="fas fa-chevron-circle-right"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl slide">
                      <figure>
                        <a
                          data-fancybox="sk"
                          href={p.photo}
                          data-caption={
                            "<b>" +
                            p.name +
                            "</b><br>" +
                            p.subtitle +
                            "<br><br><a href='s/" +
                            p.slug +
                            "' class='btn btn-primary'>Przeczytaj więcej</a>"
                          }
                        >
                          <img
                            className="img-fluid"
                            src={p.thumb}
                            alt={p.name}
                          />
                        </a>
                      </figure>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className="show-more">
        <Link to="s/" className="btn">
          <i className="fas fa-angle-down"></i> Pokaż więcej
        </Link>
      </div>
    </section>
  );
};

export default HomeSaints;
