import React from 'react'

const FraternityExternalActivities = ({fraternity}) => {
  if (fraternity.external_activities !== null && fraternity.external_activities.activities.length > 0) {
    return (
      <section className="p-worship" id="external-activities">
        <div className="container">
          <h1>Działalność zewnętrzna</h1>
          <div className='pull-left-text'>
            {fraternity.external_activities.activities.map((item, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: item.text }}></p>
            ))}
          </div>
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
}

export default FraternityExternalActivities