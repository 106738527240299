import React from "react";
import { Link } from "react-router-dom";

const HomePlaces = ({ places, carouselImages, fraternities }) => {
  let items = [];
  for(var place of places) {
    items.push({item: place, type: "place"});
  }
  for(var fraternity of fraternities) {
    items.push({item: fraternity, type: "fraternity"});
  }
  items = items.sort((a, b) => a.item.town > b.item.town ? 1 : -1);

  return (
    <section className="places" id="places">
      <div className="container">
        <h1>Miejsca</h1>
        <div className="row g-0">
          <div className="col-xl-8 align-self-center hide-for-lt-575">
            <div
              id="places-carousel"
              className="carousel slider"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {carouselImages.map((image, index) => (
                  <div
                    className={"carousel-item " + (index === 0 ? "active" : "")}
                    key={index}
                  >
                    <div className="carousel-img">
                      <img
                        src={image.file}
                        className="d-block w-100"
                        alt={image.title}
                      />
                    </div>
                    <div className="carousel-item-desc">
                      <h2>{image.title}</h2>
                      <p>{image.subtitle}</p>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#places-carousel"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Poprzedni</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#places-carousel"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Następny</span>
              </button>
            </div>
          </div>
          <div className="col-xl-4 places-sidebar">
            <div className="places-desc">
              <div className="panel panel-primary">
                <div className="panel-body">
                  <ul className="list-group">
                    {items.map((item, index) => {
                      var branch = "";
                      if(item.type === "place") {
                        switch (item.item.order_branch) {
                          case "1":
                            branch = "(Bracia)";
                            break;
                          case "2":
                            branch = "(Mniszki)";
                            break;
                          case "3":
                            branch = "(Świeccy)";
                            break;
                          default:
                            branch = "";
                        }
                        return (
                          <li className="list-group-item" key={item.item.public_id}>
                            <Link to={"/p/" + item.item.slug}>
                              <strong>{item.item.town}</strong> {branch}
                            </Link>
                          </li>
                        );
                      } else if(item.type === "fraternity") {
                        branch = "(Świeccy)";
                        return (
                          <li className="list-group-item" key={item.item.public_id}>
                            <Link to={"/f/" + item.item.slug}>
                              <strong>{item.item.town}</strong> {branch}
                            </Link>
                          </li>
                        );
                      } else {
                        return <div></div>;
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePlaces;
