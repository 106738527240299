/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import ErrorPage from "./ErrorPage";
import logo_white from "../assets/img/logo_white.png";

const AllPersonsPage = () => {
  const url = "#";
  const navigate = useNavigate();
  const [persons, setPersons] = useState();

  useEffect(() => {
    fetch("/api/persons/")
      .then((response) => response.json())
      .then((data) => {
        setPersons(data);
      })
      .catch((error) => navigate("/404"));
  }, []);

  if (persons === undefined) return <ErrorPage />;

  var direction = true;
  const personsToShow = [];
  const secondListDominicans = [];
  const secondListNonDominicans = [];
  for (var p = 0; p < persons.length; p++) {
    if (persons[p].show_on_home_page) {
      personsToShow.push(persons[p]);
    } else {
      if (persons[p].was_dominican) {
        secondListDominicans.push(persons[p]);
      } else {
        secondListNonDominicans.push(persons[p]);
      }
    }
  }

  const dominicans = [];
  const nonDominicans = [];
  var i = 0;
  for (i = 0; i < secondListDominicans.length; i += 3) {
    dominicans.push(
      <div className="row" key={i}>
        {secondListDominicans.slice(i, i + 3).map((person, index) => (
          <div className="col-4" key={person.public_id}>
            <Link to={"/s/" + person.slug}>
              {person.name}
            </Link>
          </div>
        ))}
      </div>
    );
  }
  for (i = 0; i < secondListNonDominicans.length; i += 3) {
    nonDominicans.push(
      <div className="row" key={i}>
        {secondListNonDominicans.slice(i, i + 3).map((person, index) => (
          <div className="col-4" key={person.public_id}>
            <Link to={"/s/" + person.slug}>
              {person.name}
            </Link>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <nav className="navbar fixed-top bg-dark">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col logo-main">
                <a href={url} className="place-arrow-back">
                  <i
                    className="fas fa-arrow-left"
                    onClick={() => navigate(-1)}
                  ></i>
                </a>
                &nbsp;&nbsp;
                <Link to={"/"}>
                  <img src={logo_white} className="logo-auto" alt="Logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section className="saints" id="saints">
        <div className="margin-top-100px">
          <h1>Osoby</h1>
          <div className="slides">
            <div className="row g-0">
              {personsToShow.map((p, index) => {
                if (index % 2 === 0) {
                  direction = !direction;
                }
                if (direction) {
                  return (
                    <div className="col-lg-6" key={index}>
                      <div className="row g-0">
                        <div className="col-xl slide">
                          <figure>
                            <a
                              data-fancybox="sk"
                              href={p.photo}
                              data-caption={
                                "<b>" +
                                p.name +
                                "</b><br>" +
                                p.subtitle +
                                "<br><br><a href='/#/s/" +
                                p.slug +
                                "' class='btn btn-primary'>Przeczytaj więcej</a>"
                              }
                            >
                              <img
                                className="img-fluid"
                                src={p.thumb}
                                alt={p.name}
                              />
                            </a>
                          </figure>
                        </div>
                        <div className="col-xl">
                          <div className="desc">
                            <Link to={"/s/" + p.slug}>
                              <h2>{p.name}</h2>
                              <p>{p.subtitle}</p>
                              <i className="fas fa-chevron-circle-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="col-lg-6" key={index}>
                      <div className="row g-0">
                        <div className="col-xl">
                          <div className="desc">
                            <Link to={"/s/" + p.slug}>
                              <h2>{p.name}</h2>
                              <p>{p.subtitle}</p>
                              <i className="fas fa-chevron-circle-right"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="col-xl slide">
                          <figure>
                            <a
                              data-fancybox="sk"
                              href={p.photo}
                              data-caption={
                                "<b>" +
                                p.name +
                                "</b><br>" +
                                p.subtitle +
                                "<br><br><a href='/#/s/" +
                                p.slug +
                                "' class='btn btn-primary'>Przeczytaj więcej</a>"
                              }
                            >
                              <img
                                className="img-fluid"
                                src={p.thumb}
                                alt={p.name}
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="p-interesting-facts">
        <div className="container">
          <h1>Postaci dominikańskie</h1>
          <div>{dominicans}</div>
        </div>
      </section>
      <section className="p-bibliography">
        <div className="container">
          <h1>Postaci towarzyszące</h1>
          <div>{nonDominicans}</div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AllPersonsPage;
