/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import FraternityBibliography from "../components/FraternityBibliography";
import FraternityCuriosities from "../components/FraternityCuriosities";
import FraternityDocuments from "../components/FraternityDocuments";
import FraternityEquipment from "../components/FraternityEquipment";
import FraternityExternalActivities from "../components/FraternityExternalActivities";
import FraternityHero from "../components/FraternityHero";
import FraternityInfo from "../components/FraternityInfo";
import FraternityMeetings from "../components/FraternityMeetings";
import FraternityMenu from "../components/FraternityMenu";
import FraternityPersons from "../components/FraternityPersons";
import FraternityRelationsWithOrder from "../components/FraternityRelationsWithOrder";
import FraternityTimeline from "../components/FraternityTimeline";
import FraternityTraditions from "../components/FraternityTraditions";
import PlaceNavbar from "../components/PlaceNavbar";


const FraternityPage = () => {
  let params = useParams();

  const navigate = useNavigate();

  const [fraternity, setFraternity] = useState();

  useEffect(() => {
    fetch("/api/fraternity/" + params.slug)
      .then((response) => response.json())
      .then((data) => {
        setFraternity(data);
      }).catch((error) => navigate("/404"));
  }, []);

  if (fraternity === undefined) return <div></div>;

  return (
    <div>
      <PlaceNavbar />
      <FraternityMenu fraternity={fraternity} />
      <FraternityHero fraternity={fraternity} />
      <FraternityInfo fraternity={fraternity} />
      <FraternityMeetings fraternity={fraternity} />
      <FraternityTimeline fraternity={fraternity} />
      <FraternityRelationsWithOrder fraternity={fraternity} />
      <FraternityExternalActivities fraternity={fraternity} />
      <FraternityTraditions fraternity={fraternity} />
      <FraternityEquipment fraternity={fraternity} />
      <FraternityDocuments fraternity={fraternity} />
      <FraternityCuriosities fraternity={fraternity} />
      <FraternityPersons fraternity={fraternity} />
      <FraternityBibliography fraternity={fraternity} />
      <Footer />
    </div>
  )
}

export default FraternityPage