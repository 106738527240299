import React from 'react';
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const FraternityMenu = ({fraternity}) => {
    const closeNav = () => {
        document.getElementById("myNav").style.height = "0%";
      };

  return (
    <div id="myNav" className="overlay">
      <span className="closebtn" onClick={closeNav}>
        &times;
      </span>
      <div className="overlay-content">
        <div className="container">
          <Link to="/" className="menu-item2" onClick={closeNav}>
            Strona główna
          </Link>

          <HashLink
            smooth
            to="#info"
            className="menu-item2"
            onClick={closeNav}
          >
            Informacje
          </HashLink>

          {fraternity.meetings !== null && fraternity.meetings.meetings.length > 0 ? (
            <HashLink
              smooth
              to="#meetings"
              className="menu-item2"
              onClick={closeNav}
            >
              Spotkania
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.timeline !== null && fraternity.timeline.events.length > 0 ? (
            <HashLink
              smooth
              to="#timeline"
              className="menu-item2"
              onClick={closeNav}
            >
              Historia
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.relations_with_order !== null && fraternity.relations_with_order.relations.length > 0 ? (
            <HashLink
              smooth
              to="#relations-with-order"
              className="menu-item2"
              onClick={closeNav}
            >
              Relacja z zakonem
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.external_activities !== null && fraternity.external_activities.activities.length > 0 ? (
            <HashLink
              smooth
              to="#external-activities"
              className="menu-item2"
              onClick={closeNav}
            >
              Działalność zewnętrzna
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.traditions !== null && fraternity.traditions.traditions.length > 0 ? (
            <HashLink
              smooth
              to="#traditions"
              className="menu-item2"
              onClick={closeNav}
            >
              Tradycje
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.equipment !== null && fraternity.equipment.items.length > 0 ? (
            <HashLink
              smooth
              to="#equipment"
              className="menu-item2"
              onClick={closeNav}
            >
              Wyposażenie
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.documents !== null && fraternity.documents.documents.length > 0 ? (
            <HashLink
              smooth
              to="#documents"
              className="menu-item2"
              onClick={closeNav}
            >
              Dokumenty
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.curiosity_set !== null && fraternity.curiosity_set.curiosities.length > 0 ? (
            <HashLink
              smooth
              to="#curiosities"
              className="menu-item2"
              onClick={closeNav}
            >
              Ciekawostki
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.persons !== null && fraternity.persons.length > 0 ? (
            <HashLink
              smooth
              to="#persons"
              className="menu-item2"
              onClick={closeNav}
            >
              Związani z tym miejscem
            </HashLink>
          ) : (
            <div></div>
          )}

        {fraternity.bibliography !== null && fraternity.bibliography.items.length > 0 ? (
            <HashLink
              smooth
              to="#bibliography"
              className="menu-item2"
              onClick={closeNav}
            >
              Bibliografia
            </HashLink>
          ) : (
            <div></div>
          )}

        </div>
      </div>
    </div>

  )
}

export default FraternityMenu