/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import ErrorPage from "./ErrorPage";
import logo_white from "../assets/img/logo_white.png";

const PersonPage = () => {
  const url = "#";
  let params = useParams();
  const navigate = useNavigate();
  const [person, setPerson] = useState();

  useEffect(() => {
    fetch("/api/person/" + params.slug)
      .then((response) => response.json())
      .then((data) => {
        setPerson(data);
      })
      .catch((error) => navigate("/404"));
  }, []);

  if (person === undefined) return <ErrorPage />;

  return (
    <div>
      <nav className="navbar fixed-top bg-dark">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col logo-main">
                <a href={url} className="place-arrow-back">
                  <i
                    className="fas fa-arrow-left"
                    onClick={() => navigate(-1)}
                  ></i>
                </a>
                &nbsp;&nbsp;
                <Link to={"/"}>
                  <img src={logo_white} className="logo-auto" alt="Logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <section className="about" id="saints">
        <div className="margin-top-50px">
          <h1>{person.name}</h1>
          <h4>{person.subtitle}</h4>
          <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <img className="card-img-top" src={person.photo} alt="" />
            </div>
            <div className="col-lg-8">
              <p><b>{person.summary}</b></p>
              <p>{person.body}</p>
            </div>
          </div>
          </div>
        </div>
      </section>

      {person.gallery !== null ? (
        <section className="p-gallery" id="gallery">
          <div className="container">
            <h1>Galeria</h1>
            <div className="row g-0">
              {person.gallery.photos.map((photo, index) => (
                <div className="col-md-3" key={index}>
                  <div className="p-slide">
                    <figure>
                      <a
                        data-fancybox="sk"
                        href={photo.image}
                        data-caption={
                          "<h4>" +
                          photo.title +
                          "</h4>" +
                          (photo.description !== null
                            ? "<p>" + photo.description + "</p>"
                            : "")
                        }
                      >
                        <img
                          className="img-fluid"
                          src={photo.thumb}
                          alt={photo.title}
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      <Footer />
    </div>
  );
};

export default PersonPage;
