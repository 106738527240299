import React from "react";
import swieccy_logo from "../assets/img/swieccy_logo.png";

const Footer = () => {
  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  var csrftoken = getCookie('csrftoken');

  const postForm = () => {
    const url = "/api/contact-form/";
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var content = document.getElementById("content").value;
    let data = {name: name, email: email, content: content};

    fetch(url, {
      method: "POST",
      headers: {'Content-Type': 'application/json', "X-CSRFToken": csrftoken},
      body: JSON.stringify(data)
    }).then(res => {
      console.log("Request complete! response:", res);
    });
  }

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h1 className="footer-logo">
              <a href="https://swieccy.dominikanie.pl">
                <img src={swieccy_logo} alt="Logo" />
              </a>
            </h1>
            <p>© 2022 - Świeccy Zakonu Kaznodziejskiego Prowincji Polskiej</p>
            <div className="footer-links">
              <a href="https://facebook.com/dominikanie">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/Dominikaniepl">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://pl.pinterest.com/dominikanie/">
                <i className="fab fa-pinterest"></i>
              </a>
              <a href="https://www.instagram.com/dominikanie/">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://sklep.dominikanie.pl/">
                <i className="fas fa-shopping-cart"></i>
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <div className="footer-links">
              <br />
              <p>Znasz ciekawostki związane z Polskimi Dominikanami?</p>
              <button
                type="button"
                className="contact-btn"
                data-bs-toggle="modal"
                data-bs-target="#contact"
              >
                <i className="fas fa-pen"></i> Napisz do nas!
              </button>
            </div>
          </div>
          <div
            id="contact"
            className="modal fade"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title">Napisz do nas!</h2>
                </div>
                <div className="modal-body">
                  <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="name" />
                    <label htmlFor="name">Imię i nazwisko</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="email" className="form-control" id="email" />
                    <label htmlFor="email">Adres e-mail</label>
                  </div>
                  <div className="form-floating mb-3">
                    <textarea
                      id="content"
                      name="content"
                      className="form-control"
                      style={{ height: "100px" }}
                    ></textarea>
                    <label htmlFor="content">Treść wiadomości</label>
                  </div>
                  <p>
                    Możesz również wysłać do nas maila na adres <a href="mailto:mapaops800@gmail.com">mapaops800@gmail.com</a>.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Zamknij
                  </button>
                  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={postForm}>
                    Wyślij
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
