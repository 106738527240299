import React from "react";

const PlaceAbout = ({ place }) => {
  function orderBranch(branch) {
    if (branch === "1") return "I - Bracia";
    if (branch === "2") return "II - Mniszki";
    if (branch === "3") return "III - Świeccy";
  }

  return (
    <section className="p-about" id="about">
      <div className="container">
        {place.is_sanctuary ? (
          <div className="row" style={{ marginBottom: "30px" }}>
            <div className="col">
              <center>
                <h5>{place.sanctuary_name}</h5>
              </center>
            </div>
          </div>
        ) : (
          " "
        )}
        <div className="row">
          <div className="col-xl-6">
            <b>Obecna funkcja kościoła: </b> {place.current_church_function}{" "}
            <br />
            <b>Obecna funkcja klasztoru: </b> {place.current_convent_function}{" "}
            <br />
            <b>Oryginalny styl architektoniczny: </b>{" "}
            {place.original_architectural_style} <br />
            <b>Fundator: </b>{" "}
            {place.founder !== null && place.founder.length > 0
              ? place.founder
              : "Brak danych"}{" "}
            <br />
          </div>
          <div className="col-xl-6">
            <b>Lata obecności dominikanów: </b>{" "}
            {place.dominicans_presence_years}
            <br />
            <b>Oryginalna prowincja: </b> {place.original_province} <br />
            <b>Gałąź zakonu: </b> {orderBranch(place.order_branch)} <br />
            <b>Obecność fraternii: </b>{" "}
            {place.fraternity_presence
              ? "TAK, " + place.fraternity_name
              : "NIE"}{" "}
            <br />
          </div>
        </div>
        {place.summary !== null && place.summary.length > 10 ? (
          <hr />
        ) : (
          <div></div>
        )}
      </div>
      {place.summary !== null && place.summary.length > 10 ? (
        <div
          className="container justify"
          dangerouslySetInnerHTML={{ __html: place.summary }}
        ></div>
      ) : (
        <div></div>
      )}
    </section>
  );
};

export default PlaceAbout;
