import React from 'react'

const FraternityTraditions = ({fraternity}) => {
  if (fraternity.traditions !== null && fraternity.traditions.traditions.length > 0) {
    return (
      <section className="p-interesting-facts" id="traditions">
        <div className="container">
          <h1>Tradycje</h1>
          <div className='pull-left-text'>
            {fraternity.traditions.traditions.map((item, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: item.text }}></p>
            ))}
            </div>
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
}

export default FraternityTraditions