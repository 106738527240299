/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RouteHero from '../components/RouteHero'
import RouteMenu from '../components/RouteMenu'
import RouteNavbar from '../components/RouteNavbar'
import Footer from '../components/Footer'
import RouteInformation from '../components/RouteInformation'
import RoutePlaces from "../components/RoutePlaces";

const RoutePage = ({ match }) => {
  let params = useParams();

  const navigate = useNavigate();

  const [route, setRoute] = useState();

  useEffect(() => {
    fetch("/api/route/" + params.slug)
      .then((response) => response.json())
      .then((data) => {
        setRoute(data);
      }).catch((error) => navigate("/404"));
  }, []);

  if (route === undefined) return <div></div>;

  return (
    <div>
      <RouteMenu />
      <RouteNavbar />
      <RouteHero route={route} />
      <RouteInformation route={route} />
      <RoutePlaces route={route} />
      <Footer />
    </div>
  )
}

export default RoutePage