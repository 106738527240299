import React from "react";
import { Link } from "react-router-dom";

const RoutePlaceSection = ({place, imageLeft}) => {
  if(imageLeft) {
    return (
      <section className="r-place-1" id="places">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 img">
              <img src={place.place.background} alt={place.place.town} />
            </div>
            <div className="col-lg-6 desc">
              <h2>{place.place.town}</h2>
              <h5>
                {place.place.subtitle}
              </h5>
              <div className="r-a">
                <Link to={"/p/" + place.place.slug}>
                  <span>
                    Czytaj więcej&nbsp;&nbsp;
                    <i className="fas fa-arrow-circle-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="r-place-1" id="places">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 desc">
              <h2>{place.place.town}</h2>
              <h5>
                {place.place.subtitle}
              </h5>
              <div className="r-a">
                <Link to={"/p/" + place.place.slug}>
                  <span>
                    Czytaj więcej&nbsp;&nbsp;
                    <i className="fas fa-arrow-circle-right"></i>
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 img">
              <img src={place.place.background} alt={place.place.town} />
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default RoutePlaceSection;
