import React from "react";

const HomeAbout = () => {
  return (
    <section className="about" id="about">
      <div className="container">
        <h1>O projekcie</h1>
        <p>
          Projekt „Śladami dominikanów w Polsce” jest odpowiedzią świeckich
          dominikanów na wezwanie o. Tomasza Zamorskiego OP do włączenia się w
          obchody 800-lecia przybycia dominikanów do Polski. Od czasu rekolekcji
          prowincjalnych w Korbielowie (24-27.09.2020) bracia i siostry wraz z
          o. Markiem Miławickim OP opracowywali projekt utworzenia strony, która
          zebrałaby w przystępnej formie informacje o działalności dominikanów w
          Polsce na przestrzeni 800 lat.
          <br />
          <br />
          Mamy nadzieję, że strona ta przysłuży się do szerszego poznania
          dziedzictwa zakonu i zachęci wielu do odwiedzenia miejsc z nim
          związanych!
          <br />
        </p>
      </div>
    </section>
  );
};

export default HomeAbout;

/*
<center>
  <a
    data-fancybox
    href="http://www.youtube.com/watch?v=33om6HqqIHU"
    class="video-btn youtube-media"
  >
    <i class="far fa-play-circle"></i> Zobacz film z realizacji
    projektu!
  </a>
</center>

*/
