import React from "react";

const FraternityEquipment = ({ fraternity }) => {
  if (fraternity.equipment !== null && fraternity.equipment.items.length > 0) {
    return (
      <section className="p-about" id="equipment">
        <div className="container">
          <h1>Wyposażenie</h1>
          <ol>
            {fraternity.equipment.items.map((item, index) => (
              <li><p key={index}>{item.description}</p></li>
            ))}
          </ol>
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
};

export default FraternityEquipment;
