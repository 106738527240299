import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const PlaceMap = ({ place }) => {
  const position = [place.latitude, place.longitude];
  const L = require("leaflet");

  const myIcon = L.icon({
    iconUrl: place.main_place_category.icon,
    iconSize: [30, 30],

    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
  });

  function getDioceseName(diocese) {
    if (diocese === "BI") return "białostocka";
    if (diocese === "BZ") return "bielsko-żywiecka";
    if (diocese === "BY") return "bydgoska";
    if (diocese === "CZ") return "częstochowska";
    if (diocese === "DR") return "drohiczyńska";
    if (diocese === "EB") return "elbląska";
    if (diocese === "EL") return "ełcka";
    if (diocese === "GD") return "gdańska";
    if (diocese === "GL") return "gliwicka";
    if (diocese === "GN") return "gnieźnieńska";
    if (diocese === "KA") return "kaliska";
    if (diocese === "KT") return "katowicka";
    if (diocese === "KI") return "kielecka";
    if (diocese === "KK") return "koszalińsko-kołobrzeska";
    if (diocese === "KR") return "krakowska";
    if (diocese === "LE") return "legnicka";
    if (diocese === "LU") return "lubelska";
    if (diocese === "LM") return "łomżyńska";
    if (diocese === "LW") return "łowicka";
    if (diocese === "LD") return "łódzka";
    if (diocese === "OP") return "opolska";
    if (diocese === "PE") return "pelplińska";
    if (diocese === "PL") return "płocka";
    if (diocese === "PO") return "poznańska";
    if (diocese === "PR") return "przemyska";
    if (diocese === "RA") return "radomska";
    if (diocese === "RZ") return "rzeszowska";
    if (diocese === "SA") return "sandomierska";
    if (diocese === "SI") return "siedlecka";
    if (diocese === "SO") return "sosnowiecka";
    if (diocese === "SK") return "szczecińsko-kamieńska";
    if (diocese === "SW") return "świdnicka";
    if (diocese === "TA") return "tarnowska";
    if (diocese === "TO") return "toruńska";
    if (diocese === "WA") return "warmińska";
    if (diocese === "WW") return "warszawska";
    if (diocese === "WP") return "warszawsko-praska";
    if (diocese === "WL") return "włocławska";
    if (diocese === "WR") return "wrocławska";
    if (diocese === "ZL") return "zamojsko-lubaczowska";
    if (diocese === "ZG") return "zielonogórsko-gorzowska";
    return "nie dotyczy";
  }

  function getVoivodeshipName(voivodeship) {
    if (voivodeship === "DS") return "dolnośląskie";
    if (voivodeship === "KP") return "kujawsko-pomorskie";
    if (voivodeship === "LU") return "lubelskie";
    if (voivodeship === "LB") return "lubuskie";
    if (voivodeship === "LD") return "łódzkie";
    if (voivodeship === "MA") return "małopolskie";
    if (voivodeship === "MZ") return "mazowieckie";
    if (voivodeship === "OP") return "opolskie";
    if (voivodeship === "PK") return "podkarpackie";
    if (voivodeship === "PD") return "podlaskie";
    if (voivodeship === "PM") return "pomorskie";
    if (voivodeship === "SL") return "śląskie";
    if (voivodeship === "SK") return "świętokrzyskie";
    if (voivodeship === "WM") return "warmińsko-mazurskie";
    if (voivodeship === "WP") return "wielkopolskie";
    if (voivodeship === "ZP") return "zachodniopomorskie";
    return "nie dotyczy";
  }

  var showContact = false;
  if (place.contact_phone !== null && place.contact_phone.length > 3)
    showContact = true;
  if (place.contact_mail !== null && place.contact_mail.length > 3)
    showContact = true;
  if (place.contact_other !== null && place.contact_other.length > 3)
    showContact = true;
  const contactItems = [];
  contactItems.push(<hr />);
  contactItems.push(<h3>Kontakt</h3>);
  contactItems.push(
    <p>
      {place.contact_phone !== null && place.contact_phone.length > 3
        ? "tel. " + place.contact_phone + <br />
        : ""}
      {place.contact_other !== null && place.contact_other.length > 3
        ? place.contact_other + <br />
        : ""}
      {place.contact_mail !== null && place.contact_mail.length > 3
        ? "e-mail: " +
          <a href={"mailto:" + place.contact_mail}>{place.contact_mail}</a>
        : ""}
    </p>
  );

  return (
    <section className="map" id="map">
      <div className="container">
        <h1>Lokalizacja</h1>

        <div className="row">
          <div className="col-xl-9">
            <MapContainer center={position} zoom={11} scrollWheelZoom={false}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position} icon={myIcon}>
                <Popup>
                  <center>
                    <h4>{place.town}</h4>
                    {place.subtitle}
                  </center>
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className="col-xl-3 p-info">
            <h3>Dane adresowe</h3>
            <b>Kraj:</b> {place.country} <br />
            <b>Województwo:</b> {getVoivodeshipName(place.voivodeship)}
            <br />
            <b>Diecezja:</b> {getDioceseName(place.diocese)}
            <br />
            <b>Miasto:</b> {place.zip_code} {place.town}
            <br />
            <b>Ulica (kościół):</b> {place.street_church}
            <br />
            <b>Ulica (klasztor):</b> {place.street_convent}
            <br />
            {showContact ? contactItems : <div></div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlaceMap;
