import React from "react";

const FraternityTimeline = ({ fraternity }) => {
  if (fraternity.timeline == null) {
    return <div></div>;
  }
  return (
    <section className="p-timeline" id="timeline">
      <div className="container">
        <h1>Historia</h1>
        <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          {fraternity.timeline.events
            .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
            .map((event, index) => (
              <div className="vertical-timeline-item vertical-timeline-element" key={index}>
                <div>
                  <span className="vertical-timeline-element-icon">
                    <i className="fas fa-dot-circle"></i>
                  </span>
                  <div className="vertical-timeline-element-content">
                    <h4 className="timeline-title">{event.title}</h4>
                    <p>{event.body}</p>
                    <span className="vertical-timeline-element-date">
                      {event.date}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default FraternityTimeline;
