import React from 'react'

const FraternityRelationsWithOrder = ({fraternity}) => {
  if (fraternity.relations_with_order !== null && fraternity.relations_with_order.relations.length > 0) {
    return (
      <section className="p-about" id="relations-with-order">
        <div className="container">
          <h1>Relacja z zakonem</h1>
          <center>
            {fraternity.relations_with_order.relations.map((item, index) => (
              <p key={index}>{item.text}</p>
            ))}
          </center>
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
}

export default FraternityRelationsWithOrder