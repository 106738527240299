import React from 'react'
import { HashLink } from "react-router-hash-link";
import fraternity_bg from "../assets/img/fraternity_bg.jpg";

const FraternityHero = ({fraternity}) => {
  return (
    <header>
      <section
        className="place-hero"
        id="home"
        style={{
          backgroundImage: `url(` + fraternity_bg + `)`,
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="place-hero-h">
          <div className="container">
            <div className="row hero-content">
              <h1>
                <i className="fas fa-map-marker-alt"></i>
                <br />
                {fraternity.town}
              </h1>
              <h2>{fraternity.name}</h2>
              <div className="place-hero-a">
                <HashLink smooth to="#info">
                  <i className="fas fa-arrow-circle-down"></i>&nbsp;&nbsp;
                  <span>Czytaj więcej</span>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  )
}

export default FraternityHero