import React from "react";

const HomeTimeline = ({timelineEvents}) => {
  return (
    <section className="timeline" id="timeline">
      <h1>Historia zakonu i Prowincji Polskiej</h1>
      <ol id="timeline-drag">
        {timelineEvents.map((e, index) => (
          <li key={index}>
            <div>
              <time>{e.date}</time>{e.body}
            </div>
          </li>
        ))}
        <li></li>
      </ol>
    </section>
  );
};

export default HomeTimeline;
