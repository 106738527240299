/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PlaceAbout from "../components/PlaceAbout";
import PlaceBibliography from "../components/PlaceBibliography";
import PlaceConnectedWith from "../components/PlaceConnectedWith";
import PlaceGallery from "../components/PlaceGallery";
import PlaceHero from "../components/PlaceHero";
import PlaceInterestingFacts from "../components/PlaceInterestingFacts";
import PlaceMap from "../components/PlaceMap";
import PlaceMenu from "../components/PlaceMenu";
import PlaceNavbar from "../components/PlaceNavbar";
import PlaceRoutes from "../components/PlaceRoutes";
import PlaceTimeline from "../components/PlaceTimeline";
import PlaceWorship from "../components/PlaceWorship";
import Footer from "../components/Footer";
import PlaceEquipment from "../components/PlaceEquipment";

const PlacePage = () => {
  let params = useParams();

  const navigate = useNavigate();

  const [place, setPlace] = useState();

  useEffect(() => {
    fetch("/api/place/" + params.slug)
      .then((response) => response.json())
      .then((data) => {
        setPlace(data);
      }).catch((error) => navigate("/404"));
  }, []);

  if (place === undefined) return <div></div>;

  return (
    <div>
      <PlaceNavbar />
      <PlaceMenu place={place} />
      <PlaceHero place={place} />
      <PlaceMap place={place} />
      <PlaceAbout place={place} />
      <PlaceTimeline place={place} />
      <PlaceWorship place={place} />
      <PlaceInterestingFacts place={place} />
      <PlaceGallery place={place} />
      <PlaceEquipment place={place} />
      <PlaceConnectedWith place={place} />
      <PlaceRoutes place={place} />
      <PlaceBibliography place={place} />
      <Footer />
    </div>
  );
};

export default PlacePage;
