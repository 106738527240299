import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo_white from "../assets/img/logo_white.png";

const RouteNavbar = () => {
  const url = "#";
  const navigate = useNavigate();
  const openNav = () => {
    document.getElementById("myNav").style.height = "100%";
  };

  return (
    <nav className="navbar fixed-top bg-dark">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col logo-main">
              <a href={url} className="place-arrow-back">
                <i className="fas fa-arrow-left" onClick={() => navigate(-1)}></i>
              </a>
              &nbsp;&nbsp;
              <Link to={"/"}>
                <img src={logo_white} className="logo-auto" alt="Logo" />
              </Link>
            </div>
            <div className="col nav-lang-menu">
              <span onClick={openNav}>
                <i className="fas fa-bars"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default RouteNavbar;
