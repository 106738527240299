import React from "react";
import { HashLink } from "react-router-hash-link";

const PlaceHero = ({ place }) => {
  return (
    <header>
      <section
        className="place-hero"
        id="home"
        style={{
          backgroundImage: `url(` + place.background + `)`,
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="place-hero-h">
          <div className="container">
            <div className="row hero-content">
              <h1>
                <i className="fas fa-map-marker-alt"></i>
                <br />
                {place.town}
              </h1>
              <h2>{place.subtitle}</h2>
              <div className="place-hero-a">
                <HashLink smooth to="#map">
                  <i className="fas fa-arrow-circle-down"></i>&nbsp;&nbsp;
                  <span>Czytaj więcej</span>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default PlaceHero;
