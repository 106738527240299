import React, { useState } from "react";
import { Link } from "react-router-dom";
import odrowaz120 from "../assets/img/odrowaz120.png";

const HomeHero = ({ places, persons, routes, fraternities }) => {
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const searchFeed = [];
  for (var p of places) {
    searchFeed.push({ url: "/p/" + p.slug, name: p.town, type: "Miejsce" });
  }
  for (var s of persons) {
    searchFeed.push({ url: "/s/" + s.slug, name: s.name, type: "Osoba" });
  }
  for (const r of routes) {
    searchFeed.push({
      url: "/r/" + r.slug,
      name: r.name,
      type: "Trasa",
    });
  }
  for (const f of fraternities) {
    searchFeed.push({
      url: "/f/" + f.slug,
      name: f.name + " (" + f.town + ")",
      type: "Fraternia"
    });
  }

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      const regex = new RegExp(`${text}`, "gi");
      matches = searchFeed.filter((p) => {
        return p.name.match(regex);
      });
    }
    setSuggestions(matches);
    setText(text);
  };

  return (
    <header>
      <section className="hero" id="home">
        <div className="container">
          <div className="row hero-content">
            <div className="col-md-12">
              <h1>
                <img src={odrowaz120} alt="Odrowąż" className="hero-logo" />
                <br />
                Śladami Polskich Dominikanów
              </h1>
              <h2>
                Od 800 lat głosimy wszystkim, wszędzie i na wszelkie możliwe
                sposoby. Poznaj naszą historię, odwiedź miejsca z nami związane,
                przeczytaj o naszych świętych!
              </h2>
              <div className="search">
                <input
                  type="text"
                  className="search-field"
                  placeholder="Wpisz miejsce, osobę lub nazwę trasy..."
                  onChange={(e) => onChangeHandler(e.target.value)}
                  value={text}
                />
              </div>
            </div>
          </div>
          <div className="search-results">
            {suggestions &&
              suggestions.map((suggestion, i) => (
                <Link to={suggestion.url} key={i}>
                  <div className="result">
                    <b>{suggestion.name}</b>
                    <small>{suggestion.type}</small>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </section>
    </header>
  );
};

export default HomeHero;
