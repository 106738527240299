import {
  HashRouter as Router,
  Route,
  Routes
} from "react-router-dom"

import './App.css';
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import PersonPage from "./pages/PersonPage";
import PlacePage from "./pages/PlacePage";
import RoutePage from "./pages/RoutePage";
import FraternityPage from "./pages/FraternityPage";
import AllPersonsPage from "./pages/AllPersonsPage";
import AllRoutesPage from "./pages/AllRoutesPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/p/:slug" element={<PlacePage />}></Route>
          <Route path="/r/" element={<AllRoutesPage />}></Route>
          <Route path="/r/:slug" element={<RoutePage />}></Route>
          <Route path="/s/" element={<AllPersonsPage />}></Route>
          <Route path="/s/:slug" element={<PersonPage />}></Route>
          <Route path="/f/:slug" element={<FraternityPage />}></Route>
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
