import React from "react";

const PlaceInterestingFacts = ({ place }) => {
  if (place.curiosity_set == null) {
    return <div></div>;
  }
  return (
    <section className="p-interesting-facts" id="interesting-facts">
      <div className="container">
        <h1>Ciekawostki</h1>
        <ul>
          {place.curiosity_set.curiosities.map((c, index) => (
            <li key={index}><p>{c.contents}</p></li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default PlaceInterestingFacts;
