/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import ErrorPage from "./ErrorPage";
import logo_white from "../assets/img/logo_white.png";

const AllRoutesPage = () => {
  const url = "#";
  const navigate = useNavigate();
  const [routes, setRoutes] = useState();

  useEffect(() => {
    fetch("/api/routes/")
      .then((response) => response.json())
      .then((data) => {
        setRoutes(data);
      })
      .catch((error) => navigate("/404"));
  }, []);

  if (routes === undefined) return <ErrorPage />;

  const items = [];
  for (var i = 0; i < routes.length; i += 3) {
    items.push(
      <div className="row" key={routes[i].public_id}>
        {routes.slice(i, i + 3).map((route, index) => (
          <div className="col-sm-4" key={index}>
            <div className="card">
              <img src={route.background} className="card-img-top" alt="..." />
              <div className="card-body">
                <center>
                  <h4 className="card-title">{route.name}</h4>
                  <p className="card-text">
                    {route.places.map(
                      (place, index) =>
                        place.place.town + (place.is_last ? "" : " - ")
                    )}
                  </p>
                  <Link to={"/r/" + route.slug} className="btn btn-primary">
                    Zobacz
                  </Link>
                </center>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <nav className="navbar fixed-top bg-dark">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col logo-main">
                <a href={url} className="place-arrow-back">
                  <i
                    className="fas fa-arrow-left"
                    onClick={() => navigate(-1)}
                  ></i>
                </a>
                &nbsp;&nbsp;
                <Link to={"/"}>
                  <img src={logo_white} className="logo-auto" alt="Logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section className="map" id="routes">
        <div className="container margin-top-50px">
          <h1>Trasy</h1>
          {items}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AllRoutesPage;
