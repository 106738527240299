import React from "react";

const PlaceWorship = ({ place }) => {
  const items = [];

  if (place.church_patron_1 !== null) {
    items.push({
      patron: place.church_patron_1,
      feast: place.patronal_feast_1,
    });
  }
  if (place.church_patron_2 !== null) {
    items.push({
      patron: place.church_patron_2,
      feast: place.patronal_feast_2,
    });
  }
  if (place.church_patron_3 !== null) {
    items.push({
      patron: place.church_patron_3,
      feast: place.patronal_feast_3,
    });
  }
  if (place.church_patron_4 !== null) {
    items.push({
      patron: place.church_patron_4,
      feast: place.patronal_feast_4,
    });
  }
  if (place.church_patron_5 !== null) {
    items.push({
      patron: place.church_patron_5,
      feast: place.patronal_feast_5,
    });
  }

  const res = [];
  if (items.length % 2 === 1) {
    for (var i = 0; i < items.length - 1; i += 2) {
      res.push(
        <div className="row g-2" key={i}>
          {items.slice(i, i + 2).map((item, index) => (
            <div className="col-lg-6" key={index}>
              <div className="card">
                <div className="card-body">
                  <small>Patron kościoła</small>
                  <h3 className="card-title">{item.patron}</h3>
                  <p className="card-text">{item.feast}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    res.push(
      <div className="row g-2" key={items.length}>
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <small>Patron kościoła</small>
              <h3 className="card-title">{items[items.length - 1].patron}</h3>
              <p className="card-text">{items[items.length - 1].feast}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    for (var j = 0; j < items.length; j += 2) {
      res.push(
        <div className="row g-2" key={j}>
          {items.slice(j, j + 2).map((item, index) => (
            <div className="col-lg-6" key={index}>
              <div className="card">
                <div className="card-body">
                  <small>Patron kościoła</small>
                  <h3 className="card-title">{item.patron}</h3>
                  <p className="card-text">{item.feast}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  return (
    <section className="p-worship" id="worship">
      <div className="container">
        <h1>Kult</h1>
        {res}
        {place.objects_of_worship !== null ? (
          <div>
            <hr />
            <b>Przedmioty kultu:</b>
            <ul>
              {place.objects_of_worship.objects.map((item, index) => (
                <li key={index}>{item.description}</li>
              ))}
            </ul>
          </div>
        ) : (
          " "
        )}
      </div>
    </section>
  );
};

export default PlaceWorship;
