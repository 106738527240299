import React from "react";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const RouteInformation = ({ route }) => {
  var variants = 0;

  const variantsData = [];
  if (route.pedestrian_route_available) {
    variants++;
    variantsData.push({
      title: "Wariant pieszy",
      icon: "hiking",
      distance: route.pedestrian_route_distance,
      time: route.pedestrian_route_time,
      mapUrl: route.pedestrian_route_map_url,
    });
  }

  if (route.bicycle_route_available) {
    variants++;
    variantsData.push({
      title: "Wariant rowerowy",
      icon: "bicycle",
      distance: route.bicycle_route_distance,
      time: route.bicycle_route_time,
      mapUrl: route.bicycle_route_map_url,
    });
  }

  if (route.car_route_available) {
    variants++;
    variantsData.push({
      title: "Wariant samochodowy",
      icon: "car",
      distance: route.car_route_distance,
      time: route.car_route_time,
      mapUrl: route.car_route_map_url,
    });
  }
  const colSize = 12 / variants;

  return (
    <section className="r-information" id="info">
      <div className="container">
        <h1>Przebieg trasy</h1>
        <div className="r-info-places">
          {route.places.map((place, index) => (
            <div key={place.public_id}>
              <Link to={"/p/" + place.place.slug}>
                <div className="tile">
                  <span className="tile-title">{place.place.town}</span>
                  <span className="tile-desc">{place.place.subtitle}</span>
                </div>
                {!place.is_last ? (
                  <i className="fas fa-chevron-right"></i>
                ) : (
                  <div></div>
                )}
              </Link>
            </div>
          ))}
        </div>

        <div className="r-variants">
          <div className="row">
            {variantsData.map((variant, index) => (
              <div className={"col-lg-" + colSize} key={index}>
                <div className="card">
                  <div className="card-body">
                    <div className="vehicles">
                      <h3>{variant.title}</h3>
                      <div className="row">
                        <div className="col">
                          <i className={"fas fa-" + variant.icon}></i>
                          <br />
                          <small>{variant.distance}</small>
                          <br />
                          <small>{variant.time}</small>
                        </div>
                        <div className="col vert-align">
                          <a className="contact-btn" href={variant.mapUrl}>
                            Pokaż mapę
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <MapContainer center={[52, 19]} zoom={6} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {route.places.map((place, index) => (
            <Marker position={[place.place.latitude, place.place.longitude]} key={index}>
              <Popup>
                <center>
                  <h4>{place.place.town}</h4>
                  {place.place.subtitle}
                  <br />
                  <Link to={"/p/" + place.place.slug} className="btn">
                    Zobacz więcej
                  </Link>
                </center>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </section>
  );
};

export default RouteInformation;
