import React from "react";

const PlaceGallery = ({ place }) => {
  if (place.gallery == null) {
    return <div></div>;
  }
  return (
    <section className="p-gallery" id="gallery">
      <div className="container">
        <h1>Galeria</h1>
        <div className="row g-0">
          {place.gallery.photos.map((photo, index) => (
            <div className="col-md-3" key={index}>
              <div className="p-slide">
                <figure>
                  <a
                    data-fancybox="sk"
                    href={photo.image}
                    data-caption={
                      "<h4>" +
                      photo.title +
                      "</h4>" +
                      (photo.description !== null
                        ? "<p>" + photo.description + "</p>"
                        : "")
                    }
                  >
                    <img
                      className="img-fluid"
                      src={photo.thumb}
                      alt={photo.title}
                    />
                  </a>
                </figure>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PlaceGallery;
