import React from "react";

const FraternityBibliography = ({ fraternity }) => {
  if (fraternity.bibliography == null) {
    return <div></div>;
  }

  const res = [];
  for (var i = 0; i < fraternity.bibliography.items.length; i++) {
    var s = "";
    if (fraternity.bibliography.items[i].url !== null) {
      s = fraternity.bibliography.items[i].title;
      if (fraternity.bibliography.items[i].details !== null) {
        s += " - " + fraternity.bibliography.items[i].details;
      }
      res.push(
        <li key={i}>
          <a href={fraternity.bibliography.items[i].url}>{s}</a>
        </li>
      );
    } else {
      s = fraternity.bibliography.items[i].title;
      if (fraternity.bibliography.items[i].details !== null) {
        s += " - " + fraternity.bibliography.items[i].details;
      }
      res.push(<li key={i}>{s}</li>);
    }
  }

  return (
    <section className="p-bibliography" id="bibliography">
      <div className="container">
        <h1>Bibliografia</h1>
        <ul>{res}</ul>
      </div>
    </section>
  );
};

export default FraternityBibliography;
