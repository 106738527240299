import React from "react";
import { Link } from "react-router-dom";

const FraternityPersons = ({ fraternity }) => {
  if (fraternity.persons.length > 0) {
    return (
      <section className="p-connected-with-place" id="persons">
        <div className="container">
          <h1>Związani z tym miejscem</h1>
          {fraternity.persons.map((person, index) => (
            <div key={index}>
              <div className="row margin-top-60px-on-small">
                <div className="col-lg-4">
                  <center>
                    <img src={person.thumb} alt={person.name} style={{maxWidth: "250px", maxHeight: "250px"}}/>
                  </center>
                </div>
                <div className="col-lg-8">
                  <div className="center-on-small">
                  <h4>{person.name}</h4>
                  <p dangerouslySetInnerHTML={{ __html: person.summary }}></p>
                  {person.body.length > 0 ? (
                    <Link
                      to={"/s/" + person.slug}
                      className="btn btn-secondary btn-sm"
                    >
                      Czytaj więcej
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  </div>
                </div>
              </div>
              {fraternity.persons.length - 1 !== index ? <hr /> : <div></div>}
            </div>
          ))}
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
};

export default FraternityPersons;
