import React from "react";
import { HashLink } from "react-router-hash-link";

const HomeMenu = () => {
  const closeNav = () => {
    document.getElementById("myNav").style.height = "0%";
  };

  return (
    <div id="myNav" className="overlay">
      <span className="closebtn" onClick={closeNav}>
        &times;
      </span>
      <div className="overlay-content">
        <div className="container">
          <HashLink smooth to="#home" className="menu-item2" onClick={closeNav}>
            Strona główna
          </HashLink>
          <HashLink smooth to="#map" className="menu-item2" onClick={closeNav}>
            Mapa
          </HashLink>
          <HashLink
            smooth
            to="#places"
            className="menu-item2"
            onClick={closeNav}
          >
            Miejsca
          </HashLink>
          <HashLink
            smooth
            to="#routes"
            className="menu-item2"
            onClick={closeNav}
          >
            Trasy
          </HashLink>
          <HashLink
            smooth
            to="#timeline"
            className="menu-item2"
            onClick={closeNav}
          >
            Historia
          </HashLink>
          <HashLink
            smooth
            to="#saints"
            className="menu-item2"
            onClick={closeNav}
          >
            Święci i zasłużeni
          </HashLink>
          <HashLink
            smooth
            to="#about"
            className="menu-item2"
            onClick={closeNav}
          >
            O projekcie
          </HashLink>
          <a
            href="https://zakon.dominikanie.pl"
            className="menu-item2"
            onClick={closeNav}
          >
            Zakon
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeMenu;
