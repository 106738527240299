import React from "react";
import { Link } from "react-router-dom";

const PlaceRoutes = ({ place }) => {
  if (place.routes.length === 0) {
    return <div></div>;
  }
  return (
    <section className="p-routes" id="routes">
      <div className="container">
        <h1>Trasy</h1>

        {place.routes.map((route, index) => (
          <div key={route.route.public_id}>
            <div className="card">
              <div className="card-body">
                <Link to={"/r/" + route.route.slug}>
                  <div className="row g-3">
                    <div className="col-lg-8 align-self-center">
                      <h3 className="card-title">{route.route.name}&nbsp;</h3>
                      <p className="card-text">
                        {route.route.places.map(
                          (place, index) =>
                            place.place.town + (place.is_last ? "" : " - ")
                        )}
                      </p>
                    </div>
                    <div className="col-lg-4 align-self-center">
                      <div className="vehicles">
                        <div className="row g-3">
                          {route.route.pedestrian_route_available ? (
                            <div className="col">
                              <i className="fas fa-hiking"></i>
                              <br />
                              <small>
                                {route.route.pedestrian_route_distance}
                              </small>
                              <br />
                              <small>{route.route.pedestrian_route_time}</small>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          {route.route.bicycle_route_available ? (
                            <div className="col">
                              <i className="fas fa-bicycle"></i>
                              <br />
                              <small>
                                {route.route.bicycle_route_distance}
                              </small>
                              <br />
                              <small>{route.route.bicycle_route_time}</small>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          {route.route.car_route_available ? (
                            <div className="col">
                              <i className="fas fa-car"></i>
                              <br />
                              <small>{route.route.car_route_distance}</small>
                              <br />
                              <small>{route.route.car_route_time}</small>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          <div className="col align-self-center">
                            <i className="fas fa-arrow-circle-right arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <br />
          </div>
        ))}
      </div>
    </section>
  );
};

export default PlaceRoutes;
