import React from 'react'

const FraternityInfo = ({fraternity}) => {
  function getDioceseName(diocese) {
    if (diocese === "BI") return "Białostocka";
    if (diocese === "BZ") return "Bielsko-żywiecka";
    if (diocese === "BY") return "Bydgoska";
    if (diocese === "CZ") return "Częstochowska";
    if (diocese === "DR") return "Drohiczyńska";
    if (diocese === "EB") return "Elbląska";
    if (diocese === "EL") return "Ełcka";
    if (diocese === "GD") return "Gdańska";
    if (diocese === "GL") return "Gliwicka";
    if (diocese === "GN") return "Gnieźnieńska";
    if (diocese === "KA") return "Kaliska";
    if (diocese === "KT") return "Katowicka";
    if (diocese === "KI") return "Kielecka";
    if (diocese === "KK") return "Koszalińsko-kołobrzeska";
    if (diocese === "KR") return "Krakowska";
    if (diocese === "LE") return "Legnicka";
    if (diocese === "LU") return "Lubelska";
    if (diocese === "LM") return "Łomżyńska";
    if (diocese === "LW") return "Łowicka";
    if (diocese === "LD") return "Łódzka";
    if (diocese === "OP") return "Opolska";
    if (diocese === "PE") return "Pelplińska";
    if (diocese === "PL") return "Płocka";
    if (diocese === "PO") return "Poznańska";
    if (diocese === "PR") return "Przemyska";
    if (diocese === "RA") return "Radomska";
    if (diocese === "RZ") return "Rzeszowska";
    if (diocese === "SA") return "Sandomierska";
    if (diocese === "SI") return "Siedlecka";
    if (diocese === "SO") return "Sosnowiecka";
    if (diocese === "SK") return "Szczecińsko-kamieńska";
    if (diocese === "SW") return "Świdnicka";
    if (diocese === "TA") return "Tarnowska";
    if (diocese === "TO") return "Toruńska";
    if (diocese === "WA") return "Warmińska";
    if (diocese === "WW") return "Warszawska";
    if (diocese === "WP") return "Warszawsko-praska";
    if (diocese === "WL") return "Włocławska";
    if (diocese === "WR") return "Wrocławska";
    if (diocese === "ZL") return "Zamojsko-lubaczowska";
    if (diocese === "ZG") return "Zielonogórsko-gorzowska";
    return "Nie dotyczy";
  }

  function getVoivodeshipName(voivodeship) {
    if (voivodeship === "DS") return "Dolnośląskie";
    if (voivodeship === "KP") return "Kujawsko-pomorskie";
    if (voivodeship === "LU") return "Lubelskie";
    if (voivodeship === "LB") return "Lubuskie";
    if (voivodeship === "LD") return "Łódzkie";
    if (voivodeship === "MA") return "Małopolskie";
    if (voivodeship === "MZ") return "Mazowieckie";
    if (voivodeship === "OP") return "Opolskie";
    if (voivodeship === "PK") return "Podkarpackie";
    if (voivodeship === "PD") return "Podlaskie";
    if (voivodeship === "PM") return "Pomorskie";
    if (voivodeship === "SL") return "Śląskie";
    if (voivodeship === "SK") return "Świętokrzyskie";
    if (voivodeship === "WM") return "Warmińsko-mazurskie";
    if (voivodeship === "WP") return "Wielkopolskie";
    if (voivodeship === "ZP") return "Zachodniopomorskie";
    return "Nie dotyczy";
  }

  function getFraternityStatus(status) {
    if (status === "EX") return "Istniejąca";
    if (status === "NA") return "Nieaktywna";
    if (status === "DE") return "Wymarła";
    if (status === "CD") return "Kanonicznie rozwiązana";
    if (status === "ND") return "Brak danych";
    return "Nieznany";
  }

  return (
    <section className="p-interesting-facts" id="info">
      <div className="container pull-left-text">
        <h1>Informacje</h1>
        <div className="row">
        <div className="col-xl-6">
            <b>Status fraterni: </b> {getFraternityStatus(fraternity.state)}<br />
            <b>Liczba osób w trakcie formacji: </b> {fraternity.number_of_people_in_formation}<br />
            <b>Liczba profesów wieczystych: </b> {fraternity.current_number_of_life_professed_members} <br />
            <b>Liczba zmarłych profesów od początku istnienia fraterni: </b> {fraternity.number_of_dead_members_since_foundation} <br />
            <b>Miejsce sprawowania Mszy Świętej: </b> {fraternity.holy_mass_place} <br />
          </div>
          <div className="col-xl-6">
            <b>Województwo: </b> {getVoivodeshipName(fraternity.voivodeship)}<br />
            <b>Diecezja: </b> {getDioceseName(fraternity.diocese)}<br />
            <b>Miasto: </b> {fraternity.town}<br />
            <b>Ulica: </b> {fraternity.street}<br />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FraternityInfo