import React from "react";

const PlaceEquipment = ({ place }) => {
  if (place.equipment !== null && place.equipment.items.length > 0) {
    return (
      <section className="p-worship" id="equipment">
        <div className="container">
          <h1>Wyposażenie</h1>
          <ol>
            {place.equipment.items.map((item, index) => (
              <li key={index}><p>{item.description}</p></li>
            ))}
          </ol>
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
};

export default PlaceEquipment;
