import React from 'react'
import ErrorHero from '../components/ErrorHero'

const ErrorPage = () => {
  return (
    <div>
      <ErrorHero />
    </div>
  )
}

export default ErrorPage