import React from "react";
import { HashLink } from "react-router-hash-link";
import logo_white from "../assets/img/logo_white.png";

const HomeNavbar = () => {
  const openNav = () => {
    document.getElementById("myNav").style.height = "100%";
  };

  return (
    <nav className="navbar fixed-top navbar-light bg-dark mnavbar">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col logo-main">
              <HashLink to="#home">
                <img src={logo_white} alt="Logo" />
              </HashLink>
            </div>
            <div className="col nav-lang-menu">
              <span onClick={openNav}>
                <i className="fas fa-bars"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HomeNavbar;
