import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";

const HomeMap = ({ places, placeCategories, fraternities }) => {
  const L = require("leaflet");

  const categories = [];
  for (var i = 0; i < placeCategories.length; i += 3) {
    categories.push(
      <div className="row" key={placeCategories[i].public_id}>
        {placeCategories.slice(i, i + 3).map((category, index) => (
          <div className="col-xl" key={index}>
              <img src={category.icon} alt={category.name} /> - {category.name}
          </div>
        ))}
      </div>
    );
  }

  let items = [];
  for (var place of places) {
    items.push({ item: place, type: "place" });
  }
  for (var fraternity of fraternities) {
    items.push({ item: fraternity, type: "fraternity" });
  }

  const [itemsToShow, setItemsToShow] = useState(items);

  const filter = () => {
    var step1 = [];
    var preservationState = document.getElementById("preservation_state").value;
    for (var iter1 = 0; iter1 < items.length; iter1++) {
      if (preservationState === "all") {
        step1.push(items[iter1]);
      } else if (preservationState === "ex") {
        if (
          items[iter1].type === "place" &&
          (items[iter1].item.preservation_state === "CZ" ||
          items[iter1].item.preservation_state === "ZA")
        ) {
          step1.push(items[iter1]);
        }
        if (
          items[iter1].type === "fraternity" &&
          items[iter1].item.state === "EX"
        ) {
          step1.push(items[iter1]);
        }
      } else if (preservationState === "nex") {
        if (
          items[iter1].type === "place" &&
          (items[iter1].item.preservation_state === "NZ" ||
          items[iter1].item.preservation_state === "RU")
        ) {
          step1.push(items[iter1]);
        }
        if (
          items[iter1].type === "fraternity" &&
          (items[iter1].item.state === "NA" ||
          items[iter1].item.state === "DE" ||
          items[iter1].item.state === "CD")
        ) {
          step1.push(items[iter1]);
        }
      }
    }

    var step2 = [];
    var voivodeship = document.getElementById("voivodeship").value;
    for (var iter2 = 0; iter2 < step1.length; iter2++) {
      if (step1[iter2].item.voivodeship === voivodeship || voivodeship === "ALL") {
        step2.push(step1[iter2]);
      }
    }

    var step3 = [];
    var orderBranch = document.getElementById("order_branch").value;
    for (var iter3 = 0; iter3 < step2.length; iter3++) {
      if (orderBranch === "all") {
        step3.push(step2[iter3]);
      }
      else if (orderBranch === "3" && step2[iter3].type === "fraternity") {
        step3.push(step2[iter3]);
      }
      else if (orderBranch === "1" && step2[iter3].type === "place" && step2[iter3].item.order_branch === "1") {
        step3.push(step2[iter3]);
      }
      else if (orderBranch === "2" && step2[iter3].type === "place" && step2[iter3].item.order_branch === "2") {
        step3.push(step2[iter3]);
      }
    }

    var step4 = [];
    var others = document.getElementById("others").value;
    for (var iter4 = 0; iter4 < step3.length; iter4++) {
      if (others === "all") {
        step4.push(step3[iter4]);
      } else if (others === "1" && step3[iter4].type === "place" && step3[iter4].item.is_sanctuary) {
        step4.push(step3[iter4]);
      } else if (others === "2" && step3[iter4].type === "place" && step3[iter4].item.has_miraculous_objects_of_worship) {
        step4.push(step3[iter4]);
      } else if (others === "3" && step3[iter4].type === "place" && step3[iter4].item.has_relics) {
        step4.push(step3[iter4]);
      }
    }


    setItemsToShow(step4);
  };

  return (
    <section className="map" id="map">
      <div className="container">
        <h1>Mapa</h1>
        {categories}

        <div className="map-filters">
          <div className="row">
            <div className="col-xl-3">
              <label htmlFor="preservation_state">Stan zachowania:</label>
              <select
                id="preservation_state"
                className="form-select form-select-sm"
              >
                <option value="all">Dowolny</option>
                <option value="ex">Istniejący</option>
                <option value="nex">Nieistniejący</option>
              </select>
            </div>
            <div className="col-xl-3">
              <label htmlFor="voivodeship">Województwo:</label>
              <select id="voivodeship" className="form-select form-select-sm">
                <option value="ALL">Wszystkie</option>
                <option value="DS">Dolnośląskie</option>
                <option value="KP">Kujawsko-pomorskie</option>
                <option value="LU">Lubelskie</option>
                <option value="LB">Lubuskie</option>
                <option value="LD">Łódzkie</option>
                <option value="MA">Małopolskie</option>
                <option value="MZ">Mazowieckie</option>
                <option value="OP">Opolskie</option>
                <option value="PK">Podkarpackie</option>
                <option value="PD">Podlaskie</option>
                <option value="PM">Pomorskie</option>
                <option value="SL">Śląskie</option>
                <option value="SK">Świętokrzyskie</option>
                <option value="WM">Warmińsko-mazurskie</option>
                <option value="WP">Wielkopolskie</option>
                <option value="ZP">Zachodniopomorskie</option>
              </select>
            </div>
            <div className="col-xl-3">
              <label htmlFor="order_branch">Gałąź zakonu:</label>
              <select id="order_branch" className="form-select form-select-sm">
                <option value="all">Wszystkie</option>
                <option value="1">Bracia</option>
                <option value="2">Mniszki</option>
                <option value="3">Świeccy</option>
              </select>
            </div>
            <div className="col-xl-3">
              <label htmlFor="others">Inne:</label>
              <select id="others" className="form-select form-select-sm">
                <option value="all">Wszystkie</option>
                <option value="1">Saktuaria</option>
                <option value="2">Cudowne obrazy i figurki</option>
                <option value="3">Relikwie</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-primary float-end"
                onClick={filter}
              >
                Filtruj
              </button>
            </div>
          </div>
        </div>
        <MapContainer center={[52, 19]} zoom={6} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {itemsToShow.map((item, index) => (
            <Marker
              key={index}
              position={[item.item.latitude, item.item.longitude]}
              icon={L.icon({
                iconUrl: item.item.main_place_category.icon,
                iconSize: [30, 30],
                popupAnchor: [0, 0],
                shadowUrl: null,
                shadowSize: null,
                shadowAnchor: null,
              })}
            >
              <Popup>
                <center>
                  <h4>{item.item.town}</h4>
                  {item.type === "fraternity" ? item.item.name : item.item.subtitle}
                  <br />
                  {item.type === "fraternity" ? (<Link to={"/f/" + item.item.slug} className="btn">
                    Zobacz więcej
                  </Link>) : (<Link to={"/p/" + item.item.slug} className="btn">
                    Zobacz więcej
                  </Link>)}
                </center>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </section>
  );
};

export default HomeMap;
