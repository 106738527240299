import React from 'react'

const FraternityDocuments = ({fraternity}) => {
  if (fraternity.documents !== null && fraternity.documents.documents.length > 0) {
    return (
      <section className="p-worship" id="documents">
        <div className="container">
          <h1>Dokumenty</h1>
            {fraternity.documents.documents.map((item, index) => (
              <p key={index}>{item.text}</p>
            ))}
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
}

export default FraternityDocuments