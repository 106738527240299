import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const PlaceMenu = ({ place }) => {
  const closeNav = () => {
    document.getElementById("myNav").style.height = "0%";
  };

  return (
    <div id="myNav" className="overlay">
      <span className="closebtn" onClick={closeNav}>
        &times;
      </span>
      <div className="overlay-content">
        <div className="container">
          <Link to="/" className="menu-item2" onClick={closeNav}>
            Strona główna
          </Link>

          <HashLink smooth to="#map" className="menu-item2" onClick={closeNav}>
            Mapa
          </HashLink>

          <HashLink
            smooth
            to="#about"
            className="menu-item2"
            onClick={closeNav}
          >
            Krótko o miejscu
          </HashLink>

          {place.timeline !== null ? (
            <HashLink
              smooth
              to="#timeline"
              className="menu-item2"
              onClick={closeNav}
            >
              Historia
            </HashLink>
          ) : (
            <div></div>
          )}

          <HashLink
            smooth
            to="#worship"
            className="menu-item2"
            onClick={closeNav}
          >
            Kult
          </HashLink>

          {place.curiosity_set !== null ? (
            <HashLink
              smooth
              to="#interesting-facts"
              className="menu-item2"
              onClick={closeNav}
            >
              Ciekawostki
            </HashLink>
          ) : (
            <div></div>
          )}

          {place.gallery !== null ? (
            <HashLink
              smooth
              to="#gallery"
              className="menu-item2"
              onClick={closeNav}
            >
              Galeria
            </HashLink>
          ) : (
            <div></div>
          )}

          {place.equipment !== null && place.equipment.items.length > 0 ? (
            <HashLink
              smooth
              to="#equipment"
              className="menu-item2"
              onClick={closeNav}
            >
              Wyposażenie
            </HashLink>
          ) : (
            <div></div>
          )}

          {place.persons.length > 0 ? (
            <HashLink
              smooth
              to="#connected"
              className="menu-item2"
              onClick={closeNav}
            >
              Związani z tym miejscem
            </HashLink>
          ) : (
            <div></div>
          )}

          {place.routes.length > 0 ? (
            <HashLink
              smooth
              to="#routes"
              className="menu-item2"
              onClick={closeNav}
            >
              Trasy
            </HashLink>
          ) : (
            <div></div>
          )}

          {place.bibliography !== null ? (
            <HashLink
              smooth
              to="#bibliography"
              className="menu-item2"
              onClick={closeNav}
            >
              Bibliografia
            </HashLink>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaceMenu;
