import React from "react";
import { Link } from "react-router-dom";
import odrowaz120 from "../assets/img/odrowaz120.png";

const ErrorHero = () => {
  return (
    <header>
      <section className="hero" id="home">
        <div className="container">
          <div className="row hero-content">
            <div className="col-md-12">
              <h1>
                <img src={odrowaz120} alt="Odrowąż" />
                <br />
                Bład 404 <br />
                Nie znaleziono strony!
              </h1>
              <div className="search">
                <Link to="/" className="contact-btn place-arrow-back">
                    <i className="fas fa-arrow-left"></i> Strona główna
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default ErrorHero;
