import React from "react";
import RoutePlaceSection from "./RoutePlaceSection";
import RouteInfoBar from "./RouteInfoBar";

const RoutePlaces = ({ route }) => {
  const places = [];
  var imageLeft = true;
  for (var i = 0; i < route.places.length; i++) {
    imageLeft = !imageLeft;
    places.push(
      <RoutePlaceSection
        key={i}
        place={route.places[i]}
        imageLeft={imageLeft}
      />
    );
    if (!route.places[i].is_last) {
      places.push(
        <RouteInfoBar key={route.places[i].public_id} place={route.places[i]} />
      );
    }
  }

  return <div>{places}</div>;
};

export default RoutePlaces;
