import React from "react";
import see_more from "../assets/img/see_more.png";
import { Link } from "react-router-dom";

const HomeRoutes = ({ routes }) => {
  const availableRoutes = [];
  for (var r = 0; r < routes.length; r++) {
    if (routes[r].show_on_home_page) {
      availableRoutes.push(routes[r]);
    }
  }
  const shuffled = availableRoutes.sort(() => 0.5 - Math.random());
  const routesToShow = shuffled.slice(0, 2);

  return (
    <section className="map" id="routes">
      <div className="container">
        <h1>Trasy</h1>
        <div className="row">
          {routesToShow.map((route, index) => (
            <div className="col-md-4" key={index}>
              <div className="card">
                <img
                  src={route.background}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <center>
                    <h4 className="card-title">{route.name}</h4>
                    <p className="card-text">
                      {route.places.map(
                        (place, index) =>
                          place.place.town + (place.is_last ? "" : " - ")
                      )}
                    </p>
                    <Link to={"/r/" + route.slug} className="btn btn-primary">
                      Zobacz
                    </Link>
                  </center>
                </div>
              </div>
            </div>
          ))}

          <div className="col-md-4">
            <Link to="/r/" className="see-more">
              <img
                src={see_more}
                className="card-img-top margin-top-50px"
                alt="Zobacz więcej"
              />
              <div className="card-body">
                <center>
                  <h4 className="card-title">Zobacz więcej tras</h4>
                </center>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeRoutes;
