/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import HomeAbout from "../components/HomeAbout";
import HomeHero from "../components/HomeHero";
import HomeMap from "../components/HomeMap";
import HomeMenu from "../components/HomeMenu";
import HomeNavbar from "../components/HomeNavbar";
import HomePlaces from "../components/HomePlaces";
import HomeRoutes from "../components/HomeRoutes";
import HomeSaints from "../components/HomeSaints";
import HomeTimeline from "../components/HomeTimeline";
import ErrorPage from "./ErrorPage";

const HomePage = () => {
  const navigate = useNavigate();
  const [homePageData, setHomePageData] = useState();

  useEffect(() => {
    fetch("/api/home-page/")
      .then((response) => response.json())
      .then((data) => {
        setHomePageData(data);
      })
      .catch((error) => navigate("/404"));
  }, []);

  if (homePageData === undefined) return <ErrorPage />;

  return (
    <div>
      <HomeMenu />
      <HomeNavbar />
      <HomeHero
        places={homePageData.places}
        persons={homePageData.persons}
        routes={homePageData.routes}
        fraternities={homePageData.fraternities}
      />
      <HomeMap
        places={homePageData.places}
        placeCategories={homePageData.place_categories}
        fraternities={homePageData.fraternities}
      />
      <HomePlaces
        places={homePageData.places}
        carouselImages={homePageData.carousel}
        fraternities={homePageData.fraternities}
      />
      <HomeRoutes routes={homePageData.routes} />
      <HomeTimeline timelineEvents={homePageData.timeline} />
      <HomeSaints persons={homePageData.persons} />
      <HomeAbout />
      <Footer />
    </div>
  );
};

export default HomePage;
