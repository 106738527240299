import React from "react";

const RouteInfoBar = ({ place }) => {
  var variants = 0;

  const variantsData = [];
  if (
    place.pedestrian_route_to_next_time != null &&
    place.pedestrian_route_to_next_distance != null
  ) {
    variants++;
    variantsData.push({
      icon: "hiking",
      distance: place.pedestrian_route_to_next_distance,
      time: place.pedestrian_route_to_next_time,
      mapUrl: place.pedestrian_route_to_next_map_url,
    });
  }

  if (
    place.bicycle_route_to_next_time != null &&
    place.bicycle_route_to_next_distance != null
  ) {
    variants++;
    variantsData.push({
      icon: "bicycle",
      distance: place.bicycle_route_to_next_distance,
      time: place.bicycle_route_to_next_time,
      mapUrl: place.bicycle_route_to_next_map_url,
    });
  }

  if (
    place.car_route_to_next_time != null &&
    place.car_route_to_next_distance != null
  ) {
    variants++;
    variantsData.push({
      icon: "car",
      distance: place.car_route_to_next_distance,
      time: place.car_route_to_next_time,
      mapUrl: place.car_route_to_next_map_url,
    });
  }
  const colSize = 12 / variants;

  function generateBlock(icon, distance, time, mapUrl, isLast) {
    return (
      <div className={"col-lg-" + colSize + (!isLast ? " vert-line" : "")} key={mapUrl}>
        <div className="d-flex justify-content-center">
          <div className="p-4 vert-align">
            <i className={"fas fa-" + icon}></i>
          </div>
          <div className="p-4 vert-align">
            <small>{distance}</small>
            <br />
            <small>{time}</small>
          </div>
          <div className="p-4 vert-align">
            {mapUrl != null ? (
              <a href={mapUrl}>
                <span>
                  <i className="fas fa-arrow-circle-right"></i>
                </span>
              </a>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const blocks = [];
  for (var i = 0; i < variantsData.length; i++) {
    var isLast = i === variantsData.length - 1;
    blocks.push(
      generateBlock(
        variantsData[i].icon,
        variantsData[i].distance,
        variantsData[i].time,
        variantsData[i].mapUrl,
        isLast
      )
    );
  }

  return (
    <section className="r-infobar">
      <div className="container">
        <div className="row">{blocks}</div>
      </div>
    </section>
  );
};

export default RouteInfoBar;
