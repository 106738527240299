import React from "react";
import { HashLink } from "react-router-hash-link";

const RouteHero = ({route}) => {
  return (
    <header>
      <section
        className="route-hero"
        id="home"
        style={{
          backgroundImage: `url(` + route.background + `)`,
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row hero-content">
            <h2>
              <span className="r-subtitle">Trasa</span>
            </h2>
            <h1>
              <span className="r-title">{route.name}</span>
            </h1>
            <div className="route-hero-a">
              <HashLink smooth to="#info">
                <i className="fas fa-arrow-circle-down"></i>&nbsp;&nbsp;
                <span>Zobacz więcej</span>
              </HashLink>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default RouteHero;
