import React from "react";

const PlaceBibliography = ({ place }) => {
  if (place.bibliography == null) {
    return <div></div>;
  }

  const res = [];
  for (var i = 0; i < place.bibliography.items.length; i++) {
    var s = "";
    if (place.bibliography.items[i].url !== null) {
      s = place.bibliography.items[i].title;
      if (place.bibliography.items[i].details !== null) {
        s += " - " + place.bibliography.items[i].details;
      }
      res.push(
        <li key={i}>
          <a href={place.bibliography.items[i].url}>{s}</a>
        </li>
      );
    } else {
      s = place.bibliography.items[i].title;
      if (place.bibliography.items[i].details !== null) {
        s += " - " + place.bibliography.items[i].details;
      }
      res.push(<li key={i}>{s}</li>);
    }
  }

  return (
    <section className="p-bibliography" id="bibliography">
      <div className="container">
        <h1>Bibliografia</h1>
        <ul>{res}</ul>
      </div>
    </section>
  );
};

export default PlaceBibliography;
